// type RequestMethods = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export const fetchReq = async <T = any>(url: string): Promise<T> => {
    const res = await fetch(url);
    // If the status code is not in the range 200-299,
    // we still try to parse and throw it.
    if (!res.ok) {
        const error = new Error('An error occurred while fetching the data.');
        // Attach extra info to the error object.
        error.message = await res.json();
        throw error.message;
    }
    return res.json();
};

export default fetchReq;

export const postReq = async <T = Record<string, any>>(
    url: string,
    { arg }: { arg: T },
): Promise<any> => {
    const res = await fetch(url, { method: 'POST', body: JSON.stringify(arg) });
    // If the status code is not in the range 200-299,
    // we still try to parse and throw it.
    if (!res.ok) {
        const error = new Error('An error occurred while fetching the data.');
        // Attach extra info to the error object.
        error.message = await res.json();
        throw error.message;
    }
    return res.json();
};

export const patchReq = async <T = Record<string, any>>(url: string, { arg }: { arg: T }) => {
    const res = await fetch(url, { method: 'PATCH', body: JSON.stringify(arg) });
    // If the status code is not in the range 200-299,
    // we still try to parse and throw it.
    if (!res.ok) {
        const error = new Error('An error occurred while fetching the data.');
        // Attach extra info to the error object.
        error.message = await res.json();
        throw error.message;
    }
    return res.json();
};

export const putReq = <T = Record<string, any>>(url: string, { arg }: { arg: T }) => {
    return fetch(url, { method: 'PUT', body: JSON.stringify(arg) }).then((res) => res.json());
};

export const deleteReq = async <T = Record<string, any>>(url: string, { arg }: { arg: T }) => {
    const res = await fetch(url, { method: 'DELETE', body: JSON.stringify(arg) });
    // If the status code is not in the range 200-299,
    // we still try to parse and throw it.
    if (!res.ok) {
        const error = new Error('An error occurred while making the request.');
        // Attach extra info to the error object.
        error.message = await res.json();
        throw error.message;
    }
    return res.json();
};
