export enum ServiceProviderType {
    INDIVIDUAL = 'INDIVIDUAL',
    ORGANIZATION = 'ORGANIZATION',
}

export enum SettingsTab {
    OVERVIEW = 'overview',
    MEMBERS = 'members',
    PAYOUT = 'payout',
    CONFIGURATION = 'configuration',
}

export enum Currency {
    USD = 'usd',
    INR = 'inr',
}

export enum SortDirection {
    ASC = 'asc',
    DESC = 'desc',
}

export enum OrgAccessRole {
    ADMIN = 'org:admin',
    MEMBER = 'org:member',
}

export enum NotificationType {
    REMINDER = 'reminder',
    PUSH = 'push',
}

export enum SocketEvent {
    toast = 'toast',
}
