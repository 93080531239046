export class APIError extends Error {
    public statusCode: number;
    public additionalInfo: object;
    constructor(message: string, statusCode: number, additionalInfo?: object) {
        super(message);
        this.statusCode = statusCode;
        this.additionalInfo = additionalInfo ?? {};

        Error.captureStackTrace(this, this.constructor);
    }
}
