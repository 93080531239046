import { APIError } from '@/backend/lib/error';
import { FieldInputProps, FormikProps } from 'formik';
import qs from 'qs';
import { Currency } from '../enums';
import countries from './countries';
import keys from 'lodash/keys';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import { customAlphabet, nanoid } from 'nanoid';
import { VideoCodec, videoCodecs } from 'livekit-client';

export type ObjectType = Record<string, any>;

export function isVideoCodec(codec: string): codec is VideoCodec {
    return videoCodecs.includes(codec as VideoCodec);
}

export function queryBuilder(obj: any, options?: qs.IStringifyOptions<qs.BooleanOptional>) {
    return qs.stringify(obj, options);
}

/**
 * Check if provided parameter is plain object
 * @param item
 * @returns boolean
 */
export function isObject(item: unknown): item is Record<string, unknown> {
    return item !== null && typeof item === 'object' && item.constructor === Object;
}

export function cloneDeep<T>(source: T): T {
    if (!isObject(source)) {
        return source;
    }
    const output: Record<string, unknown> = {};
    for (const key in source) {
        output[key] = cloneDeep(source[key]);
    }
    return output as T;
}

/**
 * Merge and deep copy the values of all of the enumerable own properties of target object from source object to a new object
 * @param target The target object to get properties from.
 * @param source The source object from which to copy properties.
 * @return A new merged and deep copied object.
 */
export function mergeDeep<T extends object, S extends object>(target: T, source: S): T & S {
    if (isObject(source) && Object.keys(source).length === 0) {
        return cloneDeep({ ...target, ...source });
    }

    const output = { ...target, ...source };

    if (isObject(source) && isObject(target)) {
        for (const key in source) {
            if (isObject(source[key]) && key in target && isObject(target[key])) {
                (output as Record<string, unknown>)[key] = mergeDeep(
                    target[key] as object,
                    source[key] as object,
                );
            } else {
                (output as Record<string, unknown>)[key] = isObject(source[key])
                    ? cloneDeep(source[key])
                    : source[key];
            }
        }
    }

    return output;
}

export function isBrowser(): boolean {
    return typeof window !== 'undefined';
}

export function isSmallScreen(): boolean {
    return isBrowser() && window.innerWidth < 768;
}

// Fetch currency from ISO standard currency list
export const getCurrencyOptions = () => {
    return [
        { label: '$', value: Currency.USD },
        { label: '₹', value: Currency.INR },
    ];
};

// To-Do: Location based currency selection
export const getDefaultCurrencyValue = () => {
    return Currency.INR;
};

export const getErrorMessage = (field: FieldInputProps<any>, form: FormikProps<any>) => {
    try {
        const errMsg = form?.errors[field.name] as string;
        const isTouched = form?.touched[field.name];
        return isTouched && errMsg ? errMsg : '';
    } catch (err) {
        return '';
    }
};

export const getAPIErrorMessage = (err: any, defaultMsg = '') => {
    let errMsg = defaultMsg,
        status = 400;
    if (err instanceof APIError) {
        errMsg = err.message;
        status = err.statusCode;
    } else if (err instanceof Error) {
        errMsg = err.message;
    }

    return { errMsg, status };
};

export const getDialCodeOptions = () => {
    const _countries = sortBy(countries, 'dial_code');

    return map(_countries, ({ dial_code, emoji }) => ({
        label: `${dial_code} ${emoji}`,
        value: dial_code.replace('+', ''),
    }));
};

export const formatPhoneNumber = (dialCode: string, number: string) => {
    return `${dialCode}${number}`;
};

/**
 * A function to create a comma-separated string from an array of objects.
 *
 * @param {Array<ObjectType | string>} array - The array of objects or strings.
 * @param {string} key - The key to extract from each object.
 * @return {string} The comma-separated string.
 */
export function createCommaSeparatedStringFromArray(
    array: Array<ObjectType | string>,
    key?: string,
): string {
    const stringArray: string[] = array.map((item) => {
        if (typeof item === 'object' && key) {
            return item[key];
        }
        return item;
    });
    return stringArray.join(', ');
}

/**
 * Creates an array of strings by splitting the input string at each comma and trimming each resulting element.
 *
 * @param {string} string - The input string to be split and trimmed.
 * @return {string[]} An array of strings resulting from the split and trim operations.
 */
export function createArrayFromCommaSeparatedString(string: string): string[] {
    return string.split(',').map((item) => item.trim());
}

/**
 * A function to check if there are changes between two objects.
 *
 * @param {ObjectType} oldObj - The old object to compare.
 * @param {ObjectType} newObj - The new object to compare.
 * @return {boolean} Returns true if changes are found, false otherwise.
 */
export function isChanged(oldObj: ObjectType, newObj: ObjectType): boolean {
    const hasOwnProperty = Object.prototype.hasOwnProperty;

    function compareObjects(oldObj: ObjectType, newObj: ObjectType): boolean {
        const oldKeys = Object.keys(oldObj);
        const newKeys = Object.keys(newObj);
        // Check for added or removed keys
        if (oldKeys.length !== newKeys.length) {
            return true;
        }
        // Check for value changes or nested object changes
        for (const key of oldKeys) {
            if (!hasOwnProperty.call(newObj, key)) {
                return true; // Key is missing in new object
            }
            const oldValue = oldObj[key];
            const newValue = newObj[key];
            if (Array.isArray(oldValue) && Array.isArray(newValue)) {
                if (!isEqual(oldValue, newValue)) {
                    return true; // Array has changed
                }
            } else if (typeof oldValue === 'object' && typeof newValue === 'object') {
                if (compareObjects(oldValue, newValue)) {
                    return true; // Nested object has changed
                }
            } else if (!isEqual(oldValue, newValue)) {
                return true; // Primitive value has changed
            }
        }
        return false; // No changes found
    }
    return compareObjects(oldObj, newObj);
}

/**
 * A function to get the properties that have changed between two objects.
 *
 * @param {ObjectType} oldObj - The old object to compare.
 * @param {ObjectType} updatedObj - The updated object to compare.
 * @return {ChangedProperties} An object containing the properties that have changed.
 */
export const getChangedProperties = (oldObj: ObjectType, updatedObj: ObjectType) => {
    const prevChangedObj: ObjectType = {};
    const updatedChangedObj: ObjectType = {};

    const oldKeys = keys(oldObj);
    const updatedKeys = keys(updatedObj);

    updatedKeys.forEach((key) => {
        if (oldKeys.includes(key)) {
            const oldValue = oldObj[key];
            const updatedValue = updatedObj[key];

            if (Array.isArray(oldValue) && Array.isArray(updatedValue)) {
                // Handle arrays of objects
                const changedItems = oldValue
                    .map((item, index) => {
                        if (!isEqual(item, updatedValue[index])) {
                            return {
                                old: item,
                                updated: updatedValue[index],
                            };
                        }
                        return null;
                    })
                    .filter((item) => item !== null);

                if (changedItems.length > 0) {
                    prevChangedObj[key] = changedItems.map((item) => item?.old);
                    updatedChangedObj[key] = changedItems.map((item) => item?.updated);
                }
            } else if (!isEqual(oldValue, updatedValue)) {
                // Handle non-array properties
                prevChangedObj[key] = oldValue;
                updatedChangedObj[key] = updatedValue;
            }
        }
    });

    return { prevChangedObj, updatedChangedObj };
};

export const insertComponentsIntoText = (
    str: string,
    replacements: Record<string, React.ReactNode>,
) => {
    const splitRegex = /\[\[(\w*)\]\]/g;
    const parts = str.split(splitRegex);
    return parts.map((part) => {
        if (Object.prototype.hasOwnProperty.call(replacements, part)) {
            return replacements[part];
        }
        return part;
    });
};

export function getKey(size: number = 3) {
    return nanoid(size);
}

export function generateMeetingId(segmentLength: number = 4, segmentCount: number = 4): string {
    const alphabet = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const nanoid = customAlphabet(alphabet, segmentLength);

    const segments = Array.from({ length: segmentCount }, () => nanoid());
    return segments.join('-');
}

export function encodePassphrase(passphrase: string) {
    return encodeURIComponent(passphrase);
}

export function decodePassphrase(base64String: string) {
    return decodeURIComponent(base64String);
}

export function randomString(length: number): string {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function generateRoomId(): string {
    return `${randomString(4)}-${randomString(4)}`;
}
